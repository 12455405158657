import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue')
  },
  {
    path: '/homecopy',
    name: 'homecopy',
    component: () => import('../views/HomeCopy.vue')
  },
  {
    path: '/answer',
    name: 'answer',
    component: () => import('../views/AnswerView.vue')
  },
  {
    path: '/prize',
    name: 'prize',
    component: () => import('../views/PrizeView.vue')
  },
  {
    path: '/rule',
    name: 'rule',
    component: () => import('../views/RuleView.vue')
  },
  {
    path: '/share',
    name: 'share',
    component: () => import('../views/ShareView.vue')
  },
  {
    path: '/qrcode',
    name: 'qrcode',
    component: () => import('../views/QrCode.vue')
  },
  {
    path: '/codecopy',
    name: 'codecopy',
    component: () => import('../views/CodeCopy.vue')
  },
]

const router = createRouter({
  history: createWebHistory(),
  // mode: 'history',
  routes
})

export default router
