import axios from 'axios'
import s4 from '@/utils/sm4'

// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: '/cm-api',
  // 超时
  timeout: 20000
})

// request拦截器
service.interceptors.request.use(config => {
  config.headers.Accept = 'application/json'
  config.headers['Content-Type'] = 'application/json;charset=utf-8'
  config.headers['encodeType'] = 'HexStr';
  // 对请求参数进行加密
  if (config) {
      let data = (config && config.data) || null;
      console.log(s4.strToHex(JSON.stringify(data)), 's4.strToHex(JSON.stringify(data));')
      if (data) {
        config.data = s4.strToHex(JSON.stringify(data));
      }
      // config.data='7B0D0A20202020227343686E223A312C0D0A202020202273796E63223A312C0D0A202020202270686F6E65223A223133333536353631323134222C0D0A20202020226170704964223A22313135222C0D0A20202020226F70656E4964223A22343536220D0A7D';
  }
  console.log(config, 'configconfig')
  return config
}, error => {
  console.log(error)
  Promise.reject(error)
})

//响应拦截器
service.interceptors.response.use(response => {
  if (response.status == 500) {
      console.log("服务器出现错误！");
  }
  return response;
})


export default service
