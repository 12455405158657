import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import jssdk from 'weixin-js-sdk'

import './utils/flexible.js';
// import 'amfe-flexible'

import ElementPlus from "element-plus";
// 导入 element-plus 样式
import "element-plus/dist/index.css";




const app = createApp(App)
app.config.globalProperties.$wx = jssdk
app.use(ElementPlus).use(store).use(router).mount('#app');

