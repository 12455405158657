import request from '@/utils/request'
import cmRequest from '@/utils/cmcRequest' 


//登录
export function exchange(data) {
    return request({
        url: '/jimei/auth/v1/exchange',
        method: 'post',
        data: data
    })
}
//获取微信用户信息
export function exchangeDetail(data) {
    return request({
        url: '/jimei/auth/v1/exchangeDetail',
        method: 'post',
        data: data
    })
}
//用户显示信息
export function showQueue(data) {
    return request({
        url: '/jimei/user/v1/showQueue',
        method: 'post',
        data: data
    })
}
//获取token
export function loginWeChat(data) {
    return request({
        url: `/jimei/auth/v1/loginWeChat`,
        method: 'post',
        data: data
    })
}
//获取骰子点数
export function getNextStep(data) {
    return request({
        url: '/jimei/user/v1/getNextStep',
        method: 'post',
        data: data
    })
}
//题目信息
export function getQuestion(data) {
    return request({
        url: '/jimei/user/v1/getQuestion',
        method: 'post',
        data: data
    })
}
//答题
export function answerQuestion(data) {
    return request({
        url: '/jimei/user/v1/answerQuestion',
        method: 'post',
        data: data
    })
}
//用户抽奖
export function draw(data) {
    return request({
        url: '/jimei/user/v1/draw',
        method: 'post',
        data: data
    })
}
//红包排行榜
export function rank(data) {
    return request({
        url: '/jimei/user/v1/rank',
        method: 'post',
        data: data
    })
}
//我的奖品
export function myBonus(data) {
    return request({
        url: '/jimei/user/v1/myBonus',
        method: 'post',
        data: data
    })
}
//微信分享
export function share(data) {
    return request({
        url: '/jimei/auth/v1/share',
        method: 'post',
        data: data
    })
}
//获取验证码
export function sendVerifyCode(data) {
    return request({
        url: '/jimei/auth/v1/sendVerifyCode',
        method: 'post',
        data: data
    })
}

//验证用户是否已注册
export function checkMobile(data) {
    return request({
        url: '/jimei/user/v1/checkMobile',
        method: 'post',
        data: data
    })
}
//查询用户抽奖次数
export function queryUserChance(data) {
    return request({
        url: '/jimei/user/v1/queryUserChance',
        method: 'post',
        data: data
    })
}

//APP新增用户接口
export function appUserWechat(data) {
    return request({
        url: '/jimei/auth/v1/exchangeThirdInfo',
        method: 'post',
        data: data
    })
}