<template>
  <div class="home">
    <!-- <div class="background-musice" :class="{playing: isPlay}">
      <img @click="onStop()" v-if="isPlay" src="../assets/images/stop.png" />
      <img @click="onPlay()" v-else src="../assets/images/play.png" />
    </div> -->
    <div class="share" @click="onShare()"></div>
    <div class="container">
      <div
        v-for="(item, index) in row"
        :class="{ box: index != chooseIndex, box1: index == chooseIndex }"
        :key="index"
      >
        <img
          class="spot"
          v-if="index == 1"
          :src="item?.scenicAreaImg"
        />
        <img
          class="spot"
          v-if="index == 4"
          :src="item?.scenicAreaImg"
        />
        <img
          class="spot"
          v-if="index == 8"
          :src="item?.scenicAreaImg"
        />
        <img
          class="spot"
          v-if="index == 10"
          :src="item?.scenicAreaImg"
        />
        <img class="spot" v-if="index == 11" :src="item?.scenicAreaImg" />
        <img
          class="spot"
          v-if="index == 15"
          :src="item?.scenicAreaImg"
        />
        <img
          class="spot"
          v-if="index == 18"
          :src="item?.scenicAreaImg"
        />
        <img
          class="spot"
          v-if="index == 21"
          :src="item?.scenicAreaImg"
        />
        <img class="spot" v-if="index == 26" :src="item?.scenicAreaImg" />
        <img class="spot" v-if="index == 29" :src="item?.scenicAreaImg" />
        <img
          class="spot"
          v-if="index == 30"
          :src="item?.scenicAreaImg"
        />
        <img
          class="gift"
          v-if="index == 3 || index == 20 || index == 24"
          src="../assets/images/gift.png"
        />
        <img
          class="dice"
          v-if="index == 7 || index == 12 || index == 17 || index == 31"
          src="../assets/images/dice.png"
        />
        <img class="zs" v-if="index == 28" src="../assets/images/zuanshi.png" />
      </div>
    </div>
    <span ref="diceRef" :id="diceId" :class="'dice_' + dicePoints"></span>
    <div class="btn">
      <img class="gz ig" @click="toRule()" src="../assets/images/guize.png" />
      <img
        v-if="isOk"
        class="tz"
        @click="dice()"
        data-clipboard-text="https://promotion.qxhpro.com/share"
        src="../assets/images/touzhi.png"
      />
      <img v-else class="tz" src="../assets/images/zhihui.png" />
      <img
        class="jp ig"
        @click="toPrize()"
        src="../assets/images/jiangpin.png"
      />
    </div>
    <img v-if="!isOk" class="ncishu" src="../assets/images/nocishu.png" />
    <SpotView
      v-if="spotVisible"
      :spotIndex="spotIndex"
      :spotParams="spotParams"
      :userId="userId"
      @closeSpot="closeSpot"
    />
  </div>
</template>

<script>
import Clipboard from "clipboard";
import { ElMessage } from "element-plus";
import {
  loginWeChat,
  getNextStep,
  showQueue,
  appUserWechat,
  queryUserChance,
} from "@/api/index";
import { computed, ref } from "vue";
import { setToken } from "@/utils/auth";
import SpotView from "@/components/Spot";
import { useRoute } from "vue-router";

let style = document.createElement("style");
style.innerHTML = ".el-message__content{font-size:21px;color:#000;}";
document.body.appendChild(style);
// const diceRef = ref();
export default {
  name: "HomeView",
  components: {
    SpotView,
  },
  setup() {
    const route = new useRoute();
    const point = ref(1);
    const dicePoints = computed({
      get() {
        return point.value;
      },
      set(v) {
        point.value = v;
      },
    });
    const choose = ref(-1);
    const chooseIndex = computed({
      get() {
        return choose.value;
      },
      set(v) {
        choose.value = v;
      },
    });
    const current = ref(-1);
    const currentIndex = computed({
      get() {
        return current.value;
      },
      set(v) {
        current.value = v;
      },
    });
    const target = ref(-1);
    const targetIndex = computed({
      get() {
        return target.value;
      },
      set(v) {
        target.value = v;
      },
    });
    targetIndex;
    const spot = ref(false);
    const spotVisible = computed({
      get() {
        return spot.value;
      },
      set(v) {
        spot.value = v;
      },
    });
    const rowChild = ref(new Array(34));
    const row = computed({
      get() {
        return rowChild.value;
      },
      set(v) {
        rowChild.value = v;
      },
    });
    const param = ref({});
    const spotParams = computed({
      get() {
        return param.value;
      },
      set(v) {
        param.value = v;
      },
    });
    const userRef = ref(0);
    const userId = computed({
      get() {
        return userRef.value;
      },
      set(v) {
        userRef.value = v;
      },
    });
    const diceIdRef = ref("dice");
    const diceId = computed({
      get() {
        return diceIdRef.value;
      },
      set(v) {
        diceIdRef.value = v;
      },
    });
    const musicRef = ref(null);
    const music = computed({
      get() {
        return musicRef.value;
      },
      set(v) {
        musicRef.value = v;
      },
    });
    const theRequestRef = ref({});
    const theRequest = computed({
      get() {
        return theRequestRef.value;
      },
      set(v) {
        theRequestRef.value = v;
      },
    });

    const diceTimeRef = ref(null);
    const diceTime = computed({
      get() {
        return diceTimeRef.value;
      },
      set(v) {
        diceTimeRef.value = v;
      },
    });

    const isOkRef = ref(true);
    const isOk = computed({
      get() {
        return isOkRef.value;
      },
      set(v) {
        isOkRef.value = v;
      },
    });

    const isDiceRef = ref(true);
    const isDice = computed({
      get() {
        return isDiceRef.value;
      },
      set(v) {
        isDiceRef.value = v;
      },
    });

    const isPlayRef = ref(true);
    const isPlay = computed({
      get() {
        return isPlayRef.value;
      },
      set(v) {
        isPlayRef.value = v;
      },
    });

    const userFlagRef = ref('');
    const userFlag = computed({
        get() {
            return userFlagRef.value
        },
        set(v) {
            userFlagRef.value = v;
        }
    })

    return {
      userFlag,
      isOk,
      theRequest,
      isPlay,
      routeData: route.query,
      diceId,
      userId,
      point,
      music,
      dicePoints,
      diceTime, //是否摇骰子
      chooseTime: null,
      currentIndex, //当前的位置
      chooseIndex, //过程中位置
      targetIndex, //目标位置
      checkNumber: null,
      userIndex: -1,
      startIndex: -1,
      timer: null,
      stepForward: 1,
      stepNum: 1,
      isDice,
      spotIndex: -1,
      spotParams,
      spotVisible,
      row, //当前在第几步
    };
  },
  mounted() {
    // const audio = require("../assets/images/example1.mp3");
    // this.music = new Audio(audio);
    // this.onPlay();
    // document.addEventListener('click', this.onPlay);
    // const audio = require("../assets/images/example1.mp3");
    // this.music = new Audio(audio);
    // this.music.play();
    // this.music.loop = true;

    var url = window.location.search;
    var theRequest = new Object();
    if (url.indexOf("?") != -1) {
      var str = url.substring(1);
      var strs = str.split("&");
      for (var i = 0; i < strs.length; i++) {
        theRequest[strs[i].split("=")[0]] = strs[i].split("=")[1];
      }
    }
    this.theRequest = theRequest;
    if (!theRequest.openid) {
      this.$router.push({
        path: "/qrcode"
      })
    }
    this.getLoginWeChat();
  },
  methods: {
    onShare() {
      if (window.transmitInfo) { // 安卓
          window.transmitInfo(JSON.stringify({
              type: 'appShare',
              shareType: 'custom',
              title: '集礼相送，好运连连',
              desc: '邀请好友答题，赢取微信红包',
              url: `https://promotion.qxhpro.com/share?userFlag=${this.userFlag}`,
              cover: '封面（缩略图）url'
          }));
      } else { // iOS
          try {
              window.webkit.messageHandlers.appShare.postMessage({
                  shareType: 'custom',
                  title: '集礼相送，好运连连',
                  desc: '邀请好友答题，赢取微信红包',
                  url: `https://promotion.qxhpro.com/share?userFlag=${this.userFlag}`,
                  cover: '封面（缩略图）url'
              });
          } catch {
              alert('原生app不存在appShare方法');
          }
      }
    },
    onStop() {
      this.music.pause();
      this.isPlay = false;
    },
    onPlay() {
      this.music.play();
      this.music.loop = true;
      this.isPlay = true;
    },
    getShowQueue() {
      showQueue({
        userId: this.userId,
      }).then((data) => {
        this.row = data?.result;
      });
    },
    closeSpot() {
      this.spotVisible = false;
    },
    getLoginWeChat() {
      if (this.theRequest.openid != "") {
        loginWeChat({
          openId: this.theRequest?.openid,
          appCode: this.theRequest?.appCode,
          phone: this.theRequest?.phone,
          portrait: this.theRequest?.headimgurl,
          nickName: this.theRequest?.nickName,
          userFlag: this.routeData?.userFlag
        }).then((data) => {
          const token = data.result.token;
          this.userId = data.result.userId;
          this.userFlag = data.result.userFlag;
          setToken(token);
          if (data.result.userIndex != 0) {
            this.userIndex = data.result.userIndex;
            this.chooseIndex = data.result.userIndex; //当前位置在哪个点
            // this.chooseIndex = 1;
          }
          this.getShowQueue();
        });
      }
    },
    playSound(buffer) {
      let source = context.createBufferSource();
      let gainNode = context.createGain();
      source.buffer = buffer;
      source.connect(gainNode);
      gainNode.gain.value = 1;
      gainNode.connect(context.destination);
      source.start(0);
    },
    //骰子开始动画
    dice() {
      if (this.isDice && this.theRequest.openid != "") {
        queryUserChance().then((res) => {
          if (res.result.times == 0) {
            setTimeout(() => {
              this.isOk = false;
            }, 500);
          } else {
            this.diceId = "big";
            this.isDice = false;
            let i = 1;
            this.diceTime = setInterval(() => {
              this.dicePoints = "roll_" + i;
              i++;
              i > 13 ? (i = 1) : i;
            }, 500);
            const audio = require("../assets/images/example1.mp3");
            this.music = new Audio(audio);
            this.music.play();
            this.music.loop = true;
            setTimeout(() => {
              this.getNextStep();
            }, 2000);
          }
        });
      } else {
        const clipboard = new Clipboard(".tz");
        clipboard.on("success", (e) => {
          ElMessage({ message: "链接已复制,请去微信打开", type: "success" });
          clipboard.destroy();
        });
      }
    },
    openWechatLink(url) {
      if (typeof WeixinJSBridge == "undefined") {
        if (document.addEventListener) {
          document.addEventListener(
            "WeixinJSBridgeReady",
            function () {
              openLink(url);
            },
            false
          );
        } else if (document.attachEvent) {
          document.attachEvent("WeixinJSBridgeReady", function () {
            openLink(url);
          });
          document.attachEvent("onWeixinJSBridgeReady", function () {
            openLink(url);
          });
        }
      } else {
        openLink(url);
      }
    },
    openLink(url) {
      WeixinJSBridge.invoke("openUrl", {
        url: url,
        delay: 0,
      });
    },
    //获取骰子点数
    getNextStep() {
      getNextStep({
        index: this.userIndex,
        userId: this.userId,
      }).then((data) => {
        this.forward(data.result);
      });
    },
    //跳棋走步数动画开始
    forward(result) {
      this.diceId = "dice";
      this.userIndex += result;
      clearInterval(this.diceTime);
      this.dicePoints = result;

      this.targetIndex = this.chooseIndex + this.dicePoints; //目标位置等于当前位置+骰子点数;

      this.timer = setInterval(() => {
        if (this.chooseIndex < this.targetIndex) {
          //跳棋动画开始
          if (this.chooseIndex === 33) {
            this.userIndex = 1;
            this.targetIndex = this.targetIndex - 34;
            this.chooseIndex = -1;
          }
          this.chooseIndex++;
        }
        if (this.chooseIndex === this.targetIndex) {
          this.isDice = true;
          clearInterval(this.timer); //销毁跳棋定时器
          this.music.pause();
          this.chooseIndex = this.targetIndex;
          setTimeout(() => {
            switch (this.chooseIndex) {
              case 1:
                this.spotVisible = true;
                this.spotIndex = this.row[1].scenicAreaId;
                this.spotParams = this.row[1];
                break;
              case 4:
                this.spotVisible = true;
                this.spotIndex = this.row[4].scenicAreaId;
                this.spotParams = this.row[4];
                break;
              case 8:
                this.spotVisible = true;
                this.spotIndex = this.row[8].scenicAreaId;
                this.spotParams = this.row[8];
                break;
              case 10:
                this.spotVisible = true;
                this.spotIndex = this.row[10].scenicAreaId;
                this.spotParams = this.row[10];
                break;
              case 11:
                this.spotVisible = true;
                this.spotIndex = this.row[11].scenicAreaId;
                this.spotParams = this.row[11];
                break;
              case 15:
                this.spotVisible = true;
                this.spotIndex = this.row[15].scenicAreaId;
                this.spotParams = this.row[15];
                break;
              case 18:
                this.spotVisible = true;
                this.spotIndex = this.row[18].scenicAreaId;
                this.spotParams = this.row[18];
                break;
              case 21:
                this.spotVisible = true;
                this.spotIndex = this.row[21].scenicAreaId;
                this.spotParams = this.row[21];
                break;
              case 26:
                this.spotVisible = true;
                this.spotIndex = this.row[26].scenicAreaId;
                this.spotParams = this.row[26];
                break;
              case 29:
                this.spotVisible = true;
                this.spotIndex = this.row[29].scenicAreaId;
                this.spotParams = this.row[29];
                break;
              case 30:
                this.spotVisible = true;
                this.spotIndex = this.row[30].scenicAreaId;
                this.spotParams = this.row[30];
                break;
            }
            this.spotParams.userId = this.userId;
            this.spotParams.openid = this.theRequest?.openid;
            this.spotParams.appCode = this.theRequest?.appCode;
            this.spotParams.phone = this.theRequest?.phone;
          }, 500);
        }
      }, 500);
    },

    randomNum(min, max) {
      let rang = max - min,
        rand = Math.random(),
        num = min + Math.round(rand * rang);
      return num;
    },
    //前往我的奖品
    toPrize() {
      this.$router.push({
        path: "/prize",
      });
    },
    //前往规则
    toRule() {
      this.$router.push({
        path: "/rule",
        query: {
          openId: this.theRequest?.openid,
          appCode: this.theRequest?.appCode,
          phone: this.theRequest?.phone,
          portrait: this.theRequest?.headimgurl,
          nickName: this.theRequest?.nickName,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  width: 100vw;
  height: 100vh;
  background: url("../assets/images/home-background.png") no-repeat;
  background-size: 100% 100%;
  .background-musice {
    width: 50px;
    height: 50px;
    position: absolute;
    right: 5%;
    top: 2%;
    z-index: 9999;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .playing {
    animation: myfirst2 5s infinite linear;
  }
  @keyframes myfirst2
  {
    from {transform: rotate(0deg);}
    to {transform: rotate(359deg);}
  }
    
  @keyframes myfirst
  {
    from {transform: rotate(0deg);}
    to {transform: rotate(-359deg);}
  }
  .share {
    width: 60px;
    height: 60px;
    background: url('../assets/images/home-share.png') no-repeat;
    background-size: 100% 100%;
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 9999;
  }
  .container {
    width: 100%;
    height: 100%;
    position: absolute;
    .box {
      width: 17.6%;
      height: 6.5%;
      background: url("../assets/images/box.png") no-repeat;
      background-size: 100% 100%;
      position: absolute;
      img.spot {
        width: 76%;
        height: 140%;
        margin-top: -60%;
      }
      img.gift {
        width: 52%;
        height: 90%;
        margin-top: -25%;
      }
      img.dice {
        width: 45.4%;
        height: 80%;
        margin-top: -18%;
      }
      img.zs {
        width: 54.5%;
        height: 80%;
        margin-top: -30%;
        margin-left: -10%;
      }
    }
    .box1 {
      width: 20.5%;
      height: 12.4%;
      position: absolute;
      img.spot {
        width: 66%;
        height: 72%;
      }
      img.gift {
        width: 44.2%;
        height: 45%;
        margin-top: 55px;
      }
      img.dice {
        width: 39%;
        height: 45%;
        margin-top: 35%;
      }
      img.zs {
        width: 46.75%;
        height: 43.56%;
        margin-top: 45px;
        margin-left: -10px;
      }
    }
    .box:nth-child(1) {
      background: url("../assets/images/start.png") no-repeat;
      background-size: 100% 100%;
      bottom: 17.9%;
      left: 34%;
      z-index: 99;
    }
    .box1:nth-child(1) {
      background: url("../assets/images/choose-item.png") no-repeat;
      background-size: 100% 100%;
      bottom: 17.36%;
      left: 32.4%;
      z-index: 99;
    }

    .box:nth-child(2) {
      bottom: 21%;
      left: 24.3%;
      z-index: 98;
    }
    .box1:nth-child(2) {
      background: url("../assets/images/choose-item.png") no-repeat;
      background-size: 100% 100%;
      bottom: 20.32%;
      left: 22.67%;
      z-index: 98;
    }

    .box:nth-child(3) {
      background: url("../assets/images/arrow1.png") no-repeat;
      background-size: 100% 100%;
      bottom: 23.9%;
      left: 14.5%;
      z-index: 97;
    }
    .box1:nth-child(3) {
      background: url("../assets/images/choose-item.png") no-repeat;
      background-size: 100% 100%;
      bottom: 23.4%;
      left: 12.93%;
      z-index: 97;
    }

    .box:nth-child(4) {
      width: 18.4%;
      height: 7.27%;
      background: url("../assets/images/green.png") no-repeat;
      background-size: 100% 100%;
      position: absolute;
      bottom: 26.54%;
      left: 4.93%;
      z-index: 96;
    }
    .box1:nth-child(4) {
      background: url("../assets/images/choose-item.png") no-repeat;
      background-size: 100% 100%;
      position: absolute;
      bottom: 25.92%;
      left: 3.33%;
      z-index: 96;
    }

    .box:nth-child(5) {
      bottom: 29.8%;
      left: 14.53%;
      z-index: 95;
    }
    .box1:nth-child(5) {
      background: url("../assets/images/choose-item.png") no-repeat;
      background-size: 100% 100%;
      bottom: 29.31%;
      left: 12.93%;
      z-index: 95;
    }

    .box:nth-child(6) {
      background: url("../assets/images/arrow2.png") no-repeat;
      background-size: 100% 100%;
      bottom: 32.76%;
      left: 23.87%;
      z-index: 94;
    }
    .box1:nth-child(6) {
      background: url("../assets/images/choose-item.png") no-repeat;
      background-size: 100% 100%;
      bottom: 32.27%;
      left: 22.27%;
      z-index: 94;
    }

    .box:nth-child(7) {
      bottom: 35.78%;
      left: 33.2%;
      z-index: 93;
    }
    .box1:nth-child(7) {
      background: url("../assets/images/choose-item.png") no-repeat;
      background-size: 100% 100%;
      bottom: 35.28%;
      left: 31.6%;
      z-index: 93;
    }

    .box:nth-child(8) {
      width: 18.4%;
      height: 7.27%;
      background: url("../assets/images/yellow.png") no-repeat;
      background-size: 100% 100%;
      position: absolute;
      bottom: 38.3%;
      left: 42.27%;
      z-index: 92;
    }
    .box1:nth-child(8) {
      background: url("../assets/images/choose-item.png") no-repeat;
      background-size: 100% 100%;
      position: absolute;
      bottom: 38.3%;
      left: 40.67%;
      z-index: 92;
    }

    .box:nth-child(9) {
      bottom: 41.87%;
      left: 32.67%;
      z-index: 91;
    }
    .box1:nth-child(9) {
      background: url("../assets/images/choose-item.png") no-repeat;
      background-size: 100% 100%;
      bottom: 41.38%;
      left: 31.33%;
      z-index: 91;
    }

    .box:nth-child(10) {
      background: url("../assets/images/arrow1.png") no-repeat;
      background-size: 100% 100%;
      bottom: 44.77%;
      left: 22.93%;
      z-index: 90;
    }
    .box1:nth-child(10) {
      background: url("../assets/images/choose-item.png") no-repeat;
      background-size: 100% 100%;
      bottom: 44.27%;
      left: 21.33%;
      z-index: 90;
    }

    .box:nth-child(11) {
      bottom: 47.72%;
      left: 13.2%;
      z-index: 89;
    }
    .box1:nth-child(11) {
      background: url("../assets/images/choose-item.png") no-repeat;
      background-size: 100% 100%;
      bottom: 47.23%;
      left: 11.6%;
      z-index: 89;
    }

    .box:nth-child(12) {
      bottom: 50.68%;
      left: 3.47%;
      z-index: 88;
    }
    .box1:nth-child(12) {
      background: url("../assets/images/choose-item.png") no-repeat;
      background-size: 100% 100%;
      bottom: 50.18%;
      left: 1.87%;
      z-index: 88;
    }

    .box:nth-child(13) {
      width: 18.4%;
      height: 7.27%;
      background: url("../assets/images/red.png") no-repeat;
      background-size: 100% 100%;
      bottom: 53.08%;
      left: 12.4%;
      z-index: 87;
    }
    .box1:nth-child(13) {
      background: url("../assets/images/choose-item.png") no-repeat;
      background-size: 100% 100%;
      bottom: 53.01%;
      left: 11.07%;
      z-index: 87;
    }

    .box:nth-child(14) {
      bottom: 56.71%;
      left: 3.2%;
      z-index: 86;
    }
    .box1:nth-child(14) {
      background: url("../assets/images/choose-item.png") no-repeat;
      background-size: 100% 100%;
      bottom: 56.22%;
      left: 1.6%;
      z-index: 86;
    }

    .box:nth-child(15) {
      background: url("../assets/images/arrow3.png") no-repeat;
      background-size: 100% 100%;
      bottom: 59.61%;
      left: 12.8%;
      z-index: 85;
    }
    .box1:nth-child(15) {
      background: url("../assets/images/choose-item.png") no-repeat;
      background-size: 100% 100%;
      bottom: 59.11%;
      left: 11.2%;
      z-index: 85;
    }

    .box:nth-child(16) {
      bottom: 62.5%;
      left: 22.4%;
      z-index: 84;
    }
    .box1:nth-child(16) {
      background: url("../assets/images/choose-item.png") no-repeat;
      background-size: 100% 100%;
      bottom: 62.01%;
      left: 20.8%;
      z-index: 84;
    }

    .box:nth-child(17) {
      bottom: 65.39%;
      left: 32.13%;
      z-index: 83;
    }
    .box1:nth-child(17) {
      background: url("../assets/images/choose-item.png") no-repeat;
      background-size: 100% 100%;
      bottom: 64.9%;
      left: 30.53%;
      z-index: 83;
    }

    .box:nth-child(18) {
      width: 18.4%;
      height: 7.27%;
      background: url("../assets/images/yellow.png") no-repeat;
      background-size: 100% 100%;
      position: absolute;
      bottom: 67.86%;
      left: 41.33%;
      z-index: 82;
    }
    .box1:nth-child(18) {
      background: url("../assets/images/choose-item.png") no-repeat;
      background-size: 100% 100%;
      bottom: 67.24%;
      left: 39.73%;
      z-index: 82;
    }

    .box:nth-child(19) {
      bottom: 65.27%;
      left: 51.47%;
      z-index: 83;
    }
    .box1:nth-child(19) {
      background: url("../assets/images/choose-item.png") no-repeat;
      background-size: 100% 100%;
      bottom: 64.78%;
      left: 49.87%;
      z-index: 83;
    }

    .box:nth-child(20) {
      bottom: 62.25%;
      left: 60.8%;
      z-index: 84;
    }
    .box1:nth-child(20) {
      background: url("../assets/images/choose-item.png") no-repeat;
      background-size: 100% 100%;
      bottom: 61.76%;
      left: 59.2%;
      z-index: 84;
    }

    .box:nth-child(21) {
      width: 18.4%;
      height: 7.27%;
      background: url("../assets/images/yellow.png") no-repeat;
      background-size: 100% 100%;
      position: absolute;
      bottom: 58.74%;
      left: 70%;
      z-index: 85;
    }
    .box1:nth-child(21) {
      background: url("../assets/images/choose-item.png") no-repeat;
      background-size: 100% 100%;
      bottom: 58.13%;
      left: 68.4%;
      z-index: 85;
    }

    .box:nth-child(22) {
      bottom: 56.16%;
      left: 61.2%;
      z-index: 86;
    }
    .box1:nth-child(22) {
      background: url("../assets/images/choose-item.png") no-repeat;
      background-size: 100% 100%;
      bottom: 55.67%;
      left: 59.6%;
      z-index: 86;
    }

    .box:nth-child(23) {
      background: url("../assets/images/arrow4.png") no-repeat;
      background-size: 100% 100%;
      bottom: 53.26%;
      left: 51.6%;
      z-index: 87;
    }
    .box1:nth-child(23) {
      background: url("../assets/images/choose-item.png") no-repeat;
      background-size: 100% 100%;
      bottom: 52.78%;
      left: 50%;
      z-index: 87;
    }

    .box:nth-child(24) {
      bottom: 50.37%;
      left: 42%;
      z-index: 88;
    }
    .box1:nth-child(24) {
      background: url("../assets/images/choose-item.png") no-repeat;
      background-size: 100% 100%;
      bottom: 49.88%;
      left: 40.4%;
      z-index: 88;
    }

    .box:nth-child(25) {
      width: 18.4%;
      height: 7.27%;
      background: url("../assets/images/cyan.png") no-repeat;
      background-size: 100% 100%;
      bottom: 46.99%;
      left: 51.73%;
      z-index: 89;
    }
    .box1:nth-child(25) {
      background: url("../assets/images/choose-item.png") no-repeat;
      background-size: 100% 100%;
      bottom: 46.98%;
      left: 50.13%;
      z-index: 89;
    }

    .box:nth-child(26) {
      background: url("../assets/images/arrow5.png") no-repeat;
      background-size: 100% 100%;
      bottom: 44.46%;
      left: 61.2%;
      z-index: 90;
    }
    .box1:nth-child(26) {
      background: url("../assets/images/choose-item.png") no-repeat;
      background-size: 100% 100%;
      bottom: 43.97%;
      left: 59.6%;
      z-index: 90;
    }

    .box:nth-child(27) {
      bottom: 41.56%;
      left: 70.93%;
      z-index: 91;
    }
    .box1:nth-child(27) {
      background: url("../assets/images/choose-item.png") no-repeat;
      background-size: 100% 100%;
      bottom: 41.07%;
      left: 69.33%;
      z-index: 91;
    }

    .box:nth-child(28) {
      bottom: 38.67%;
      left: 80.67%;
      z-index: 92;
    }
    .box1:nth-child(28) {
      background: url("../assets/images/choose-item.png") no-repeat;
      background-size: 100% 100%;
      bottom: 38.18%;
      left: 79.07%;
      z-index: 92;
    }

    .box:nth-child(29) {
      width: 18.4%;
      height: 7.27%;
      background: url("../assets/images/yellow.png") no-repeat;
      background-size: 100% 100%;
      bottom: 34.98%;
      left: 70.8%;
      z-index: 93;
    }
    .box1:nth-child(29) {
      background: url("../assets/images/choose-item.png") no-repeat;
      background-size: 100% 100%;
      bottom: 35.1%;
      left: 70%;
      z-index: 93;
    }

    .box:nth-child(30) {
      bottom: 32.39%;
      left: 81.2%;
      z-index: 94;
    }
    .box1:nth-child(30) {
      background: url("../assets/images/choose-item.png") no-repeat;
      background-size: 100% 100%;
      bottom: 31.9%;
      left: 79.6%;
      z-index: 94;
    }

    .box:nth-child(31) {
      bottom: 29.5%;
      left: 71.87%;
      z-index: 95;
    }
    .box1:nth-child(31) {
      background: url("../assets/images/choose-item.png") no-repeat;
      background-size: 100% 100%;
      bottom: 29%;
      left: 70.27%;
      z-index: 95;
    }

    .box:nth-child(32) {
      width: 18.4%;
      height: 7.27%;
      background: url("../assets/images/purple.png") no-repeat;
      background-size: 100% 100%;
      bottom: 26.11%;
      left: 61.87%;
      z-index: 96;
    }
    .box1:nth-child(32) {
      background: url("../assets/images/choose-item.png") no-repeat;
      background-size: 100% 100%;
      bottom: 25.99%;
      left: 61.33%;
      z-index: 96;
    }

    .box:nth-child(33) {
      background: url("../assets/images/arrow4.png") no-repeat;
      background-size: 100% 100%;
      bottom: 23.65%;
      left: 53.2%;
      z-index: 97;
    }
    .box1:nth-child(33) {
      background: url("../assets/images/choose-item.png") no-repeat;
      background-size: 100% 100%;
      bottom: 23.15%;
      left: 51.6%;
      z-index: 97;
    }

    .box:nth-child(34) {
      bottom: 20.75%;
      left: 43.73%;
      z-index: 98;
    }
    .box1:nth-child(34) {
      background: url("../assets/images/choose-item.png") no-repeat;
      background-size: 100% 100%;
      bottom: 20.26%;
      left: 42.13%;
      z-index: 98;
    }
  }

  #dice {
    width: 23.5%;
    height: 11%;
    display: block;
    position: absolute;
    bottom: 27%;
    left: 37%;
  }
  #big {
    width: 23.5%;
    height: 11%;
    display: block;
    position: absolute;
    bottom: 26%;
    left: 37%;
    z-index: 999;
    transform: scale(1.4);
  }
  span.dice_1 {
    background: url("../assets/images/1.png") no-repeat;
    background-size: 180px 180px;
  }
  span.dice_2 {
    background: url("../assets/images/2.png") no-repeat;
    background-size: 180px 180px;
  }
  span.dice_3 {
    background: url("../assets/images/3.png") no-repeat;
    background-size: 180px 180px;
  }
  span.dice_4 {
    background: url("../assets/images/4.png") no-repeat;
    background-size: 180px 180px;
  }
  span.dice_5 {
    background: url("../assets/images/5.png") no-repeat;
    background-size: 180px 180px;
  }
  span.dice_6 {
    background: url("../assets/images/6.png") no-repeat;
    background-size: 180px 180px;
  }
  span.dice_roll_1 {
    background: url("../assets/images/7.png") no-repeat -25px -25px;
    background-size: 230px 230px;
    -webkit-animation: shake-in 0.5s infinite;
    -o-animation: shake-in 0.5s infinite;
    animation: shake-in 0.5s infinite;
  }
  span.dice_roll_2 {
    background: url("../assets/images/8.png") no-repeat -35px -15px;
    background-size: 230px 230px;
    -webkit-animation: shake-in 0.5s infinite;
    -o-animation: shake-in 0.5s infinite;
    animation: shake-in 0.5s infinite;
  }
  span.dice_roll_3 {
    background: url("../assets/images/9.png") no-repeat -25px -25px;
    background-size: 230px 230px;
    -webkit-animation: shake-in 0.5s infinite;
    -o-animation: shake-in 0.5s infinite;
    animation: shake-in 0.5s infinite;
  }
  span.dice_roll_4 {
    background: url("../assets/images/10.png") no-repeat -25px -25px;
    background-size: 230px 230px;
    -webkit-animation: shake-in 0.5s infinite;
    -o-animation: shake-in 0.5s infinite;
    animation: shake-in 0.5s infinite;
  }
  span.dice_roll_5 {
    background: url("../assets/images/11.png") no-repeat -35px -15px;
    background-size: 230px 230px;
    -webkit-animation: shake-in 0.5s infinite;
    -o-animation: shake-in 0.5s infinite;
    animation: shake-in 0.5s infinite;
  }
  span.dice_roll_6 {
    background: url("../assets/images/12.png") no-repeat -25px -25px;
    background-size: 230px 230px;
    -webkit-animation: shake-in 0.5s infinite;
    -o-animation: shake-in 0.5s infinite;
    animation: shake-in 0.5s infinite;
  }
  span.dice_roll_7 {
    background: url("../assets/images/13.png") no-repeat -25px -25px;
    background-size: 230px 230px;
    -webkit-animation: shake-in 0.5s infinite;
    -o-animation: shake-in 0.5s infinite;
    animation: shake-in 0.5s infinite;
  }
  span.dice_roll_8 {
    background: url("../assets/images/14.png") no-repeat -35px -15px;
    background-size: 230px 230px;
    -webkit-animation: shake-in 0.5s infinite;
    -o-animation: shake-in 0.5s infinite;
    animation: shake-in 0.5s infinite;
  }
  span.dice_roll_9 {
    background: url("../assets/images/15.png") no-repeat -25px -25px;
    background-size: 230px 230px;
    -webkit-animation: shake-in 0.5s infinite;
    -o-animation: shake-in 0.5s infinite;
    animation: shake-in 0.5s infinite;
  }
  span.dice_roll_10 {
    background: url("../assets/images/16.png") no-repeat -25px -25px;
    background-size: 230px 230px;
    -webkit-animation: shake-in 0.5s infinite;
    -o-animation: shake-in 0.5s infinite;
    animation: shake-in 0.5s infinite;
  }
  span.dice_roll_11 {
    background: url("../assets/images/17.png") no-repeat -35px -15px;
    background-size: 230px 230px;
    -webkit-animation: shake-in 0.5s infinite;
    -o-animation: shake-in 0.5s infinite;
    animation: shake-in 0.5s infinite;
  }
  span.dice_roll_12 {
    background: url("../assets/images/18.png") no-repeat -25px -25px;
    background-size: 230px 230px;
    -webkit-animation: shake-in 0.5s infinite;
    -o-animation: shake-in 0.5s infinite;
    animation: shake-in 0.5s infinite;
  }
  span.dice_roll_13 {
    background: url("../assets/images/19.png") no-repeat -25px -25px;
    background-size: 230px 230px;
    -webkit-animation: shake-in 0.5s infinite;
    -o-animation: shake-in 0.5s infinite;
    animation: shake-in 0.5s infinite;
  }
  @keyframes shake-in {
    0% {
      transform: translateX(9%);
    }
    16.6% {
      transform: translateX(-9%);
    }
    33.33% {
      transform: translateX(6%);
    }
    50% {
      transform: translateX(-6%);
    }
    66.6% {
      transform: translateX(3%);
    }
    83.33% {
      transform: translateX(-3%);
    }
    100% {
      transform: translateX(0);
    }
  }
  @-o-keyframes shake-in {
    0% {
      transform: translateX(9%);
    }
    16.6% {
      transform: translateX(-9%);
    }
    33.33% {
      transform: translateX(6%);
    }
    50% {
      transform: translateX(-6%);
    }
    66.6% {
      transform: translateX(3%);
    }
    83.33% {
      transform: translateX(-3%);
    }
    100% {
      transform: translateX(0);
    }
  }
  @-ms-keyframes shake-in {
    0% {
      transform: translateX(9%);
    }
    16.6% {
      transform: translateX(-9%);
    }
    33.33% {
      transform: translateX(6%);
    }
    50% {
      transform: translateX(-6%);
    }
    66.6% {
      transform: translateX(3%);
    }
    83.33% {
      transform: translateX(-3%);
    }
    100% {
      transform: translateX(0);
    }
  }
  @-moz-keyframes shake-in {
    0% {
      transform: translateX(9%);
    }
    16.6% {
      transform: translateX(-9%);
    }
    33.33% {
      transform: translateX(6%);
    }
    50% {
      transform: translateX(-6%);
    }
    66.6% {
      transform: translateX(3%);
    }
    83.33% {
      transform: translateX(-3%);
    }
    100% {
      transform: translateX(0);
    }
  }
  @-webkit-keyframes shake-in {
    0% {
      transform: translateX(9%);
    }
    16.6% {
      transform: translateX(-9%);
    }
    33.33% {
      transform: translateX(6%);
    }
    50% {
      transform: translateX(-6%);
    }
    66.6% {
      transform: translateX(3%);
    }
    83.33% {
      transform: translateX(-3%);
    }
    100% {
      transform: translateX(0);
    }
  }
  @-khtml-keyframes shake-in {
    0% {
      transform: translateX(9%);
    }
    16.6% {
      transform: translateX(-9%);
    }
    33.33% {
      transform: translateX(6%);
    }
    50% {
      transform: translateX(-6%);
    }
    66.6% {
      transform: translateX(3%);
    }
    83.33% {
      transform: translateX(-3%);
    }
    100% {
      transform: translateX(0);
    }
  }

  .btn {
    width: 100%;
    .ig {
      width: 21%;
      height: 9.9%;
      position: absolute;
      bottom: 10.4%;
      z-index: 99;
    }
    .gz {
      left: 6.4%;
    }
    .jp {
      right: 6.4%;
    }
    .tz {
      width: 37%;
      height: 6%;
      position: absolute;
      bottom: 6.3%;
      left: 50%;
      margin-left: -18.7%;
    }
  }
  .sz {
    display: block;
    transition: transform 1s ease; /* 添加过渡效果 */
    transform: rotate3d(x, y, z, 360deg);
  }
  .ncishu {
    width: 60%;
    height: 200px;
    position: absolute;
    top: 45%;
    left: 20%;
    margin-top: -100px;
    z-index: 9999;
  }
}
</style>
