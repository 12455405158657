<template>
  <div class="spot-view" @click="close($event)">
    <div class="content">
      <img
      class="spot-image"
      v-for="item in spotList" 
      :key="item.scenicAreaId"
      v-show="item.scenicAreaId == spotParams.scenicAreaId"
      :src="item.scenicAreaId == spotParams.scenicAreaId ? item.url : ''" />
      <img
        @click="toAnswer()"
        class="spot-btn"
        src="../../assets/images/spot-images/ydt.png"
      />
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { computed, ref } from 'vue';
export default {
  name: "spotView",
  props: {
    spotIndex: {
      type: Number,
      default: -1,
    },
    spotParams: {
      type: Object,
      default: () => ({}),
    },
    userId: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const router = new useRouter();
    const toAnswer = () => {
        router.push({
            path: "/answer",
            query: {
              scenicAreaId: props.spotParams.scenicAreaId,
              index: props.spotParams.index,
              appCode: props.spotParams.appCode,
              userId: props.spotParams.userId,
              openid: props.spotParams.openid,
              phone: props.spotParams.phone
            },
        })
    }
    return {
      props,
      toAnswer,
      spotList: [
        {
          scenicAreaId: 4,
          url: require("../../assets/images/spot-images/yby.jpg"),
        },
        {
          scenicAreaId: 10,
          url: require("../../assets/images/spot-images/slct.jpg"),
        },
        {
          scenicAreaId: 5,
          url: require("../../assets/images/spot-images/mnxq.jpg"),
        },
        {
          scenicAreaId: 7,
          url: require("../../assets/images/spot-images/cns.jpg"),
        },
        {
          scenicAreaId: 3,
          url: require("../../assets/images/spot-images/ds.jpg"),
        },
        {
          scenicAreaId: 8,
          url: require("../../assets/images/spot-images/gly.jpg"),
        },
        {
          scenicAreaId: 2,
          url: require("../../assets/images/spot-images/jgys.jpg"),
        },
        {
          scenicAreaId: 1,
          url: require("../../assets/images/spot-images/lyz.jpg"),
        },
        {
          scenicAreaId: 6,
          url: require("../../assets/images/spot-images/llmx.jpg"),
        },
        {
          scenicAreaId: 11,
          url: require("../../assets/images/spot-images/mk.jpg"),
        },
        {
          scenicAreaId: 9,
          url: require("../../assets/images/spot-images/cykj.jpg"),
        },
      ]
    };
  },
  mounted() {
    console.log(this.props.spotParams, "spotParamsspotParams");
  },
  methods: {
    close(e) {
      console.log(e, "eeee");
      let className = e.srcElement.className;
      console.log(className, "classNameclassName");
      if (className == "spot-view") {
        this.$emit("closeSpot");
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.spot-view {
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  background: url("../../assets/images/spot-images/spot-background.png")
    no-repeat;
  background-size: 100% 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9991;
  .content {
    position: absolute;
    width: 68.8%;
    height: 43.1%;
    top: 27.46%;
    left: 15.6%;
    z-index: 9992;
    box-sizing: border-box;
    .spot-image {
      width: 100%;
      height: 100%;
    }
    .spot-btn {
      width: 41%;
      height: 5.2%;
      position: fixed;
      top: 62%;
      left: 29.5%;
    }
  }
}
</style>